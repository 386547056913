import * as React from "react";
import Layout from "./layout";

import Intro from '../components/Intro/intro.jsx'
import MyStory from '../components/MyStory/mystory.jsx'
import MyWork from '../components/MyWork/mywork.jsx'
import MyPlayground from '../components/MyPlayground/myplayground.jsx'



const IndexPage = () => {
  return (
    <Layout>
      <Intro />

      <MyStory />
      <MyWork />
      {/* <MyPlayground /> */}


    </Layout>
  );
};

export default IndexPage;
