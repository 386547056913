import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Button from "../buttons/button";

const Intro = () => {
  return (
    <div className="u-margin-bottom-section intro">
      <div className="intro__pic">
        <StaticImage src="../../images/emojiMe.png" alt="Emoji pic of Tyler Gumb" placeholder="blurred" />
      </div>
      <div className="intro__right">
        <h1>Hello I'm Tyler.</h1>
        <p>
          A <span className="intro__right__yellow">front end developer</span> & <span className="intro__right__orange">UX designer</span> with a
          background in <span className="intro__right__red">entrepreneurship</span>.
          <br />Based in Seattle, WA.
        </p>
        <div className="intro__buttons">
          <Button link="mailto: tgumb100@gmail.com">Email</Button>
          <Button link="https://www.linkedin.com/in/tyler-gumb-08a772129">LinkedIn</Button>
          <Button link="https://www.dropbox.com/s/zav92gse7pnb6n4/Tyler%20Gumb%20Resume%202021.pdf?dl=0">CV</Button>
        </div>
      </div>
    </div>
  );
};

export default Intro;
