import React from "react";
import Button from "../buttons/button";
import GaliniWorkImage from "./images/galiniWorkImage";
import MemeWarsImage from "./images/memewarsWorkImage";

const WorkCard = ({ title, roles, description, link="/", techArr }) => {
  return (
    <div className="workcard">
      <div className="workcard__left">
        {title === "Galini Health" ? (
          <GaliniWorkImage />
        ) : title === "Meme Wars" ? (
          <MemeWarsImage />
        ) : (
          ""
        )}
      </div>
      <div className="workcard__right">
        <h2 className="workcard__title">{title}</h2>
        <p className="workcard__roles">Role: {roles}</p>
        <p className="workcard__description">{description}</p>
        <div className="workcard__buttonContainer">
          {techArr.map((text, index) => (
            <Button type="tech" key={index}>{text}</Button>
          ))}
        </div>
        <hr className="workcard__line" />
        <div className="workcard__link">
          <a href={link} target="_blank" rel="noopener noreferrer">{title === "Meme Wars" ? "Learn More" : "Go to website"}</a> <div>&#10095;</div>
        </div>
      </div>
    </div>
  );
};

export default WorkCard;
