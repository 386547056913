import React from "react";

import SectionTitle from "../sectionTitle";

import WorkCard from "./workCard";

const MyWork = () => {
  return (
    <div className="u-margin-bottom-section mywork">
      <div className="mywork__leftContainer">
        <SectionTitle>My Work</SectionTitle>
      </div>
      <div className="mywork__rightContainer">
        <WorkCard
          title="Galini Health"
          roles="Founder, Development, UX Design"
          description="A personalized subscription service for the management of stress and anxiety with natural supplements."
          techArr={["Gatsby", "GraphQL", "Snipcart", "DatoCMS"]}
          srcText="../../images/work-galini-pic.png"
          altText="Anxiety relief supplements"
          link="http://www.galinihealth.com/"
        />
        <div className="u-margin-top-work">
          <WorkCard
            title="Meme Wars"
            roles="Founder, UX Design, Prototyping"
            description="A cryptocurrency based meme battling game with wagering mechanics, utilizing (at the time) cutting edge NFT technology and blinded two-sided staking."
            techArr={["Figma", "Principle", "React", "PhaserJS"]}
            srcText="../../images/work-galini-pic.png"
            altText="Anxiety relief supplements"
            link="https://youtu.be/GE6its5ruI0?t=14596"
          />
        </div>
      </div>
    </div>
  );
};

export default MyWork;
