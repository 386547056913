import React from 'react'
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const MemeWarsImage = () => {
    const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "work-memewars-pic.png" }) {
        childrenImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const image = getImage(data.file.childrenImageSharp[0]);

  return (
    <GatsbyImage image={image} alt="Screenshot of Meme Wars mobile game" objectPosition="15% 40%" />
  )
}

export default MemeWarsImage;