import React, { useState, useEffect } from "react";

import { isBrowser } from "react-device-detect";

const SectionTitle = ({ children }) => {
  let hideScrollPoint;

  switch (children) {
    case "My Story":
      hideScrollPoint = 650;
      break;
    case "My Work":
      hideScrollPoint = 1570;
      break;
    default:
      hideScrollPoint = 9999;
  }

  const [vis, setVis] = useState(true);

  const listenScrollEvent = () => {
    window.scrollY > hideScrollPoint ? setVis(false) : setVis(true);
  };
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    if(isBrowser) window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  });

  return (
    <div
      className={`sectionTitle u-animate-hide ${
        !vis && `u-animate-hide__hidden`
      }`}
    >
      <div className="sectionTitle__text">{children}</div>
      <div className="sectionTitle__lineContainer">
        <hr />
      </div>
    </div>
  );
};

export default SectionTitle;
