import React from 'react'
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const GaliniWorkImage = () => {
    const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "work-galini-pic.png" }) {
        childrenImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  const image = getImage(data.file.childrenImageSharp[0]);

  return (
    <GatsbyImage image={image} alt="Anxiety relief box of supplements" objectPosition="30% 25%" />
  )
}

export default GaliniWorkImage;