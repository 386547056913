import React from "react";
import SectionTitle from "../sectionTitle";

const MyStory = () => {
  return (
    <div className="u-margin-bottom-section mystory">
      <div className="mystory__leftContainer">
        <SectionTitle>My Story</SectionTitle>
      </div>
      <div className="mystory__description">
        I'm a front end developer with 3 years of experience building and
        designing for early-stage startups I founded. I have extensive knowledge in building
        React and GatsbyJS web apps. 
        <br /><br /><span className="intro__right__orange">Why work with me? 🦄</span> It is possible to find experts who combine UX & UI, or even UI & front-end development skills. But to combine the skills to create amazing user experience and the technical ability to bring that to life, it takes one rare gem.  
        
        <br /><br /><span className="intro__right__orange">The rare gem you've been looking for 💎</span> I take pride in bringing an unusually broad skill set encompassing UX design that speeds up workflow. I don’t just seamlessly communicate with designers, but also provide deep design feedback. Tight on resources? No problem. I can take up some UX design work as well. I’m that rare. 
      </div>
    </div>
  );
};

export default MyStory;
